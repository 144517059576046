$font-family-sans-serif: Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$body-bg: #f2f2f2;

$primary: #225b54;
$secondary: #373a3c;
$danger: #ff0039;

$menu-text-color: #96bbb5;
$menu-background: #225b54;
$menu-header-background: #1e504a;
