
.dropdown-venue-select span.venue-name {
  max-width: 220px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block !important;
  float: left;
}

.dropdown-venue-select .dropdown-menu>li>a {
  white-space: normal;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-venue-select>.dropdown-menu {
  width: 260px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended.dropdown-venue-select .dropdown-menu .dropdown-menu-list>li>a {
  padding: 6px 15px 8px;
  font-size: 13px;
  border-bottom: 1px solid #646b89!important;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended.dropdown-venue-select .dropdown-menu .dropdown-menu-list>li:last-child>a {
  border-bottom: 0!important;
}


/* here you can put your own css to customize and override the theme */
.table-middle > thead > tr > th,
.table-middle > thead > tr > td,
.table-middle > tbody > tr > th,
.table-middle > tbody > tr > td,
.table-middle > tfoot > tr > th,
.table-middle > tfoot > tr > td {
  vertical-align: middle;
}

/* Custom modal show effect */
/*.modal.fade .modal-dialog {
    transform: scale(0);
    opacity: 0;
    -webkit-transition: all .25s linear;
    -o-transition: all .25s linear;
    transition: all .25s linear;
}

.modal.fade.in .modal-dialog {
    opacity: 1;
    transform: scale(1);
}*/

.position-relative {
  position: relative;
}

#notificationsModuleAlerts:empty {
  display: none;
}
.page-sidebar-menu-closed > li.heading {
  display: none;
}

.btn-pagination {
  color: #333;
  background-color: #fff;
  border-color: #ececec;
}

.btn-pagination:hover,
.btn-pagination:focus,
.btn-pagination:active {
  color: #333;
  background-color: #ececec;
  border-color: #ececec;
}

.rc-slider {
  position: relative;
  height: 4px;
  width: 100%;
  border-radius: 6px;
  background-color: #e9e9e9;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #abe2fb;
  z-index: 1;
}
.rc-slider-handle {
  position: absolute;
  margin-left: -7px;
  margin-top: -5px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  border-radius: 50%;
  border: solid 2px #96dbfa;
  background-color: #fff;
  z-index: 2;
}
.rc-slider-handle:hover {
  border-color: #57c5f7;
}
.rc-slider-handle-active:active {
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
}
.rc-slider-mark {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  font-size: 12px;
  z-index: 3;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}
.rc-slider-mark-text-active {
  color: #666;
}
.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
  z-index: 1;
}
.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}
.rc-slider-dot:first-child {
  margin-left: -4px;
}
.rc-slider-dot:last-child {
  margin-left: -4px;
}
.rc-slider-dot-active {
  border-color: #96dbfa;
}
.rc-slider-disabled {
  background-color: #e9e9e9;
}
.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}
.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  background-color: #fff;
  cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}
.rc-slider-vertical {
  width: 4px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-track {
  bottom: 0;
  width: 4px;
}
.rc-slider-vertical .rc-slider-handle {
  position: absolute;
  margin-left: -5px;
  margin-bottom: -7px;
}
.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 10px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  -webkit-animation-name: rcSliderTooltipZoomDownIn;
  animation-name: rcSliderTooltipZoomDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  -webkit-animation-name: rcSliderTooltipZoomDownOut;
  animation-name: rcSliderTooltipZoomDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@-webkit-keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  100% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  100% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-webkit-keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
  }
}
.rc-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  z-index: 4;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-tooltip-hidden {
  display: none;
}
.rc-tooltip-placement-top {
  padding: 4px 0 8px 0;
}
.rc-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}
.rc-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
}


/** Import products pagination **/
.page-selector-addon,
.page-selector-input {
  float: left;
}

.page-selector-addon {
  padding:6px 12px;
}
.page-selector {
  width: 80px;
}
.page-selector .Select-control {
  height: 34px;
}
.page-selector .Select-input,
.page-selector .Select-placeholder, :not(.Select--multi) > .Select-control .Select-value {
  line-height: 32px;
  color: #555555;
}


/** Import products CSS **/
#productImport {
  position: relative;
}
.results.import-running:before {
  content: ' ';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0.5;
  z-index: 98;
}

.results-stats {
  padding: 0 0 10px;
}

.results-row {
  border-left: 4px solid #BFCAD1;
  margin: 0 10px 20px;
  padding: 10px 10px 0;
}

.results-row:after {
  display: block;
  content: ' ';
  background: #eaeaea;
  height: 1px;
  margin-top: 15px;
}

.results-row.is-selected {
  border-color: #26C281;
}
.results-row.is-selected.partial-selected {
  border-color: #F3C200;
}

.results-row.is-imported {
  /*opacity: 0.70;*/
  color: #bfbfbf;
}

.results-row .product-name {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 7px;
  cursor: pointer;
}
.results-row .row-action {
  float: left;
  width: 45px;
  height: 45px;
  text-align: center;
}

.results-row .row-action span {

}

.results-row .row-details {
  padding-left: 50px;
}

.product-imported {
  font-size: 18px;
}

.product-checkbox,
.bottlesize-checkbox {
  font-size: 16px;
  border: 1px solid #d9d9d9;
  width: 20px;
  display: block;
  height: 20px;
  /*margin-top: 5px;*/
  margin: auto;
  cursor: pointer;
  line-height: 1.2;
}
.product-checkbox > i.fa.fa-check {
  display: none;
  font-size: 16px;
  /*color: #26C281 !important;*/
  margin-top: 2px;
}

.product-checkbox.checked > i.fa.fa-check {
  display: block;
}

.results-row .bottlesize-size {
  cursor: pointer;
}

.bottlesize-checkbox {
  width: 15px;
  height: 15px;
  font-size: 13px;
  margin-right: 10px;
  display: inline-block;
}
.bottlesize-checkbox > i.fa.fa-check {
  display: block;
  opacity: 0;
}

.bottlesize-checkbox.checked > i.fa.fa-check {
  opacity: 1;
}

.product-checkbox.imported,
.bottlesize-checkbox.imported {
  opacity: 0.55;
}

.import-pending-approval {
  position: fixed;
  right: 10px;
  top: 80px;
  z-index: 999;
  background: #E43A45;
  padding: 10px 30px;
  /* box-shadow: 0px 0px 4px 3px #dcdcdc; */
  border-radius: 5px;
  color: #fff;
  /*text-align: center;*/
  /* font-size: 16px; */
  min-width: 250px;
}

@media (max-width: 991px) {
  .import-pending-approval {
    position: static;
  }
}


/** Product management **/

.products-management .pagination-filters {
  background: #fff;
  border-radius: 4px;
  margin: 0 0 15px;
  padding: 15px 15px 5px;
}

#productsManagement .results-stats-container {
  display: none;
}

.results-heading {
  font-size: 16px;
  font-family: Roboto;
  text-transform: uppercase;

  padding-bottom: 15px;
  padding-top: 15px;

  border-bottom: 2px solid #d8d8d8;

  padding-left: 20px;
  padding-right: 20px;

  color: #232D3F;
}

.product-row {
  /*cursor: pointer;*/
  border-bottom: 1px solid #d8d8d8;
  position: relative;
}

.product-row:last-child {
  border-bottom: 0;
}

.product-row .row-actions {
  position: absolute;
  /*left: 60px;
  top: 38px;*/
  right: 10px;
  top: 5px;
  font-size: 13px;
  display: none;
}

.product-row:hover {
  background: #f5f5f5;
}

.product-row:hover .row-actions {
  display: block;
}


.product-row .row-details {
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px;
}
.product-row .product-name {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #242d3e;
}

.product-row {

  .product-name > .name-holder {
    min-width: 400px;
  }

  .catname {
    /*padding: 6px 8px;*/
  }
}

.product-row.is-selected .product-name {
  color: #ef6543;
}

.product-row .row-selected-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -8px;
  font-size: 20px;
  display: none;
  color: #ef6543;
}
.product-row.is-selected .row-selected-icon {
  /*display: block;*/
}

.bottle-size-details > div {
  font-size: 13px;
  font-family: "Roboto";
  display: inline-block;
  padding-right: 0;
  margin-right: 8px;
  /*border-right: 1px solid #232D3F;*/
  color: #232D3F;
  width: 110px;
  text-align: center;
  margin-bottom: 10px;
}

.bottle-size-details > div.bsize {
  width: 70px;
}
.bottle-size-details > div.catname {
  width: 300px;
  text-align: left;
}
/*.bottle-size-details > div:after {
    display: inline-block;
    content: ' ';
    border-right: 1px solid #232D3F;
    !*border-right: 1px solid #bfbfbf;*!
    width: 1px;
    height: 10px;
    !* background: #232D3F; *!
    margin-left: 10px;
}
.bottle-size-details > div:last-child:after {
    display: none;
}*/

.bottle-size-details > div > div {
  margin-bottom: 5px;
}

/** Details window **/
.product-details .product-name {
  font-family: "Roboto";
  color: #232D3F;
}

.details-row {
  padding: 10px 0 10px;
  border-bottom: 1px solid #d1d3d4;
  font-size: 15px;
  font-family: Roboto;
}

.product-details-container .sticky-outer-wrapper.active .portlet {
  margin-top: 15px;
}


/*
#bottlesize-add-modal-.modal-backdrop {
    z-index: 10051;
}
*/
.modal.fade.in {
  display: block;
}

.animated {
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both
}

@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0)
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px)
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px)
  }
}

@-moz-keyframes shake {
  0%, 100% {
    -moz-transform: translateX(0)
  }
  10%, 30%, 50%, 70%, 90% {
    -moz-transform: translateX(-10px)
  }
  20%, 40%, 60%, 80% {
    -moz-transform: translateX(10px)
  }
}

@-o-keyframes shake {
  0%, 100% {
    -o-transform: translateX(0)
  }
  10%, 30%, 50%, 70%, 90% {
    -o-transform: translateX(-10px)
  }
  20%, 40%, 60%, 80% {
    -o-transform: translateX(10px)
  }
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0)
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px)
  }
  20%, 40%, 60%, 80% {
    transform: translateX(10px)
  }
}

.shake {
  -webkit-animation-name: shake;
  -moz-animation-name: shake;
  -o-animation-name: shake;
  animation-name: shake
}

/*.modal {
    display: block
}*/

.modal-content > .close {
  position: absolute;
  top: 10px;
  right: 10px
}

@media (min-width: 768px) {
  .modal-sm > .modal-dialog {
    width: 300px
  }
}

@media (min-width: 992px) {
  .modal-lg > .modal-dialog {
    width: 900px
  }
}

.jquery-notific8-notification {
  height: 70px;
  max-height: 200px;
}

.loading-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  top: 0;
  z-index: 1;
  text-align: center;
  padding-top: 30px;
  border-radius: 0 0 5px 5px;
  font-size: 18px;
}

.checkbox-list-fixed-height {
  padding-top: 7px;
  max-height: 150px;
  overflow: auto;
}

.checkbox-list-fixed-height label.checkbox {
  min-height: 20px;
}

.recipe-product-card {
  background: #f2f2f2;
  padding: 5px 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: 1px 1px 2px #ccc;
}

.recipe-product-add {
  background: #fff;
  box-shadow: none;
  border-bottom: 0;
  border-radius: 0;
  margin-bottom: 0;
}

.recipe-product-card a.remove {
  float: right;
  padding-top: 10px;
}

.Select-input>input {
  padding: 0;
}

.reports-search-bar {
  margin-bottom: 10px;
}

.btn-density-wizzard {
  margin-top: 3px;
}




/** Stock take table **/
.st-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;

  .st-thead {
    border-bottom: 1px solid #d3d3d3;
  }

  td,
  th {
    padding: 8px;
    position: relative;
    color: #232d3f;
  }

  th {
    text-transform: uppercase;
    font-weight: 600;
  }

  .st-body {
    td {
      border-right: 0;
      /*border-bottom: 1px solid #ececec;*/
      padding: 10px 8px;
      font-size: 13px;
    }

    .st-category-row td {
      font-size: 14px;
      background: rgba(218, 218, 218, 0.29);
      padding: 12px 10px;
      text-transform: uppercase;
      font-weight: 800;
      border-bottom: 0;
      color: #000;
    }

    .st-product-row td {
      &:first-child {
        padding-left: 25px;
      }
    }
  }

  .st-sort-link {
    &.sort-asc {
      &:before {
        display: inline-block;
        content: "\e155";

        font-family: 'Glyphicons Halflings';
        font-style: normal;
        font-weight: 400;
        margin-right: 5px;
        /*float: left;*/
        font-size: 12px;
      }
    }

    &.sort-desc {
      &:before {
        display: inline-block;
        content: "\e156";

        font-family: 'Glyphicons Halflings';
        font-style: normal;
        font-weight: 400;
        margin-right: 5px;
        /*float: left;*/
        font-size: 12px;
      }
    }
  }
}

.product-bottlesizes {
  margin-top: 25px;
}

.table-product-bottlesizes {


  > thead > tr > th {
    text-align: center;
    border-bottom: 0;
    font-size: 13px;
    font-family: Roboto;
    font-weight: normal;
  }

  > tbody > tr {
    > td {
      border-top: 0;
      border-bottom: 1px solid #e7ecf1;
    }

    &:last-child {
      > td {
        border-top: 0;
        border-bottom: 0;
      }
    }
  }

  > thead > tr > th,
  > tbody > tr > td {
    text-align: center;
    font-size: 13px;
    font-family: Roboto;
    vertical-align: middle;
  }
}

.product-col-select-container {
  min-width: 300px;

  @media (min-width: 768px) {
    max-width: 550px;
  }

  @media (min-width: 1200px) {
    max-width: none;
  }

  .Select--multi {

    .Select-input {
      min-width: 30px;
    }

    .Select-value {
      background-color: rgb(255, 255, 255);
      border-radius: 3px;
      border: 1px solid #cccccc;
      color: #555;
      display: inline-block;
      font-size: 13px;
      line-height: 1.4;
      margin-left: 8px;
      margin-top: 5px;
    }

    .Select-value-icon {
      cursor: pointer;
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
      padding: 1px 5px;
      border-right: 1px solid #ccc;
    }
  }

}

/** Fluid table **/
$break-large: 1200px;
.fluid-table {

  margin-bottom: 20px;
  table-layout: fixed;

  @media (min-width: $break-large) {
    width: auto;
    max-width: none;
    table-layout: auto;
  }

  .fluid-thead {
    border-bottom: 1px solid #d3d3d3;

    > tr > td {
      border-bottom: 0;
    }
  }

  td,
  th {
    padding: 8px;
    position: relative;
    color: #232d3f;
  }

  th {
    text-transform: uppercase;
    font-weight: 600;
    background: #e7ecf1;

    vertical-align: bottom !important;
  }

  .fluid-body {
    td {
      border-right: 0;
      padding: 8px;
      vertical-align: middle;
      font-size: 13px;
    }

    .st-product-row td {
      &:first-child {
        padding-left: 25px;
      }
    }
  }
}

.input-xs {
  height: 25px;
  padding: 3px 10px;
  font-size: 12px;
  line-height: 1.2;
  border-radius: 3px;
}

.product-list-actions {
  background: #ffffff;
  padding: 10px;
  border-radius: 4px 4px 0 0;

  .mt-radio, .mt-checkbox {
    margin-bottom: 5px;
  }
}

.sticky-outer-wrapper.active {
  .product-list-actions {
    border-bottom: 1px solid #d3d3d3;
    box-shadow: 0 4px 3px -3px #d3d3d3;
  }
}


.product-list-manage {
  .list-header,
  .list-column {
    float: left;
    padding: 8px;
    line-height: 1.42857;
    vertical-align: middle;
    font-size: 14px;

  }
  .list-header {

    border-bottom: 2px solid #e7ecf1;
    font-weight: 600;
    text-transform: uppercase;
  }

  .list-column {
    border-top: 1px solid #e7ecf1;
    min-height: 42px;
  }

  .list-row:first-child {
    .list-column {
      border-top: 0;
    }
  }

  .list-headers {
    background: #fff;
  }

}


/** Various classes */
.user-venue-groups-tab {
  .checkbox {
    margin-bottom: 15px;

    label {
      font-weight: 700;

      i.fa {
        margin-left: -20px;
        margin-right: 6px;
      }
    }
  }
}
.venue-group-venues {
  padding-left: 20px;
  font-style: italic;
}

.is-cloning {
  position: relative;
}
.cloning-blocking {
  background: #fff;
  opacity: 0.5;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}


#translations-tabs {
  .nav.nav-tabs {
    display: none;
  }
}

li.dropdown-language {
  img {
    height: 20px;
  }

  >.dropdown-toggle {
    padding: 28px 12px 22px !important;
  }

  .dropdown-menu {
    li {
      a.active {
        background: #606683;
      }
    }
  }
}

.translation-message {

  margin-bottom: 5px;
  /* border-bottom: 1px solid #f2f2f2; */
  padding-bottom: 10px;

  .original-message {
    padding: 3px 1px 3px;
    font-size: 13px;

    > span {
      font-style: italic;
      /* text-decoration: underline; */
      margin-left: 5px;
    }
  }

  .translated-message {

    > input {
      margin-left: 15px;
    }
  }
}

.sticky-outer-wrapper.active .language-actions  {
  border-bottom: 1px solid #d3d3d3;
  -webkit-box-shadow: 0 4px 3px -3px #d3d3d3;
  box-shadow: 0 4px 3px -3px #d3d3d3;
}

.language-actions {
  background: #fff;
  margin: 0 -20px;
  padding: 10px 20px 10px;
}

ul.languages-list {
  list-style: none;
  margin-left: 0;
  padding-left: 0;

  > li {
    width: 230px;
    float: left;
    padding: 10px;
    border: 1px solid #ececec;
    margin-right: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
  }
}

.table-package-name {
  max-width: 200px;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  /* word-wrap: break-word; */
  overflow: hidden;
}

.loginContainer .page-header {
  margin: 0 0 11px;
  border-bottom: 0;
}

.row-actions.actions-short {
  text-align: right;
  position: relative;
  width: 150px;
  float: right;

  > a {
    display: inline-block;
    min-width: 20px;
    font-size: 16px;
    text-align: center;
  }

  > div.dropdown {
    display: inline-block;
  }

  > .loading {

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    background: rgba(255,255,255,1);
    z-index: 999;
  }
}

.form-file-dropzone {
  padding: 6px 10px 0;
  border: 1px solid #ccc;
  background: #f2f2f2;
  width: 250px;
  text-align: center;
  font-size: 14px;
  color: #717171;
  font-style: italic;
  cursor: pointer;
  margin-top: 10px;
}

.logo-preview {
  height: 48px;
  width: auto;
  margin-top: 5px;
  max-width: 250px;
  margin: 5px;
}

.logo-preview img {
  /*width: 100%;*/
  height: 100%;
}

.sidebar-header img {
  height: 48px;
  width: auto;
  max-width: 250px;
  margin: 5px 10px;
  float: left;
}

.sidebar-header>a {
  line-height: 58px;
  max-width: 250px;
  overflow: hidden;
  height: 58px;
  word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
}

.main-loading {
  position: absolute;
  top: 40%;
  text-align: center;
  left: 0;
  right: 0;
  font-size: 26px;
}
.main-loading > span {
  /* display: block; */
  margin-left: 15px;
}

.login-logo span {
  /*word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  display: block;*/
  display: inline-block;
  word-wrap: break-word;
  word-break: break-all;
  font-size: 24px;
  font-weight: 400;
  margin-top: 10px;
}
.login-logo img {
  height: 50px;
  width: auto;
  /* max-width: 250px; */
  margin: 0 15px 0 0;
  display: inline-block;
  /* float: left; */
  max-width: 250px;

}

.clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
li.list-group-item {
  z-index: 1100;
}

li.list-group-item {
  padding: 0;
  @extend .clearfix;

  > div {
    /*display: block;
    float: left;*/
    padding: 10px 15px;

    &.column-name {
      padding-left: 0;
    }

    &.move-handler {
      padding: 7px 12px;
      text-align: center;
      font-size: 20px;

      i {
        cursor: pointer;
        color: #bbbbbb;
      }
    }
  }
}

.modal-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255,255,255,0.7);
  /* background: #000; */
  z-index: 2;
  text-align: center;

  >p {
    top: 45%;
    position: absolute;
    left: 0;
    right: 0;
  }
}

/*.sidebar-nav > li {
  border-bottom: 1px solid #2b2b2b;
}*/
.content {
  .tab-content {
    background: #fff;
  }

  .tab-pane {
    padding: 20px;
    border: 1px solid #dddddd;
    border-top: 0;
  }


  .nav-tabs {
    .nav-link {
      border-bottom-color: transparent;
      padding: 0.8em 1.2em;
    }

    .nav-link.active,
    .nav-item.show .nav-link {
      background-color: #fff;
      border: 1px solid #dddddd;
      border-bottom-color: transparent;
      padding: 0.8em 1.2em;
    }
  }
}

#toast-container {
  &.toast-top-center {

    > div {
      width: 100%;
      border-radius: 0;
      text-align: center;
       background-image: none;
      padding: 18px 15px 15px;
      min-height: 58px;
    }
  }

  >.sc-toast {
    background-image: none !important;
  }

  >div {
    margin-bottom: 0;
    box-shadow: none !important;
    opacity: 1;

    &:hover {
      box-shadow: 0 -1px 7px #000;
    }
  }

  .toast-message {
    font-size: 16px;
  }

  .toast-close-button {
    font-size: 30px;
    top: -6px;
    line-height: 1;
  }
}

.col-with-dropdown {
  overflow: inherit !important;
}



.voip-status {
  display: block;
  margin: auto;
  vertical-align: middle;
  font-size: 18px;
}

/*
.voip-online {
  background: #3fb61a;
}

.voip-offline {
  background: red;
  opacity: 0.3;
}*/
